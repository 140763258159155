$(document).ready(function() {



	// Throttled resize function
	$(window).on('resize', function() {
		doStuff();
		fixLargeBanner();
	});


	function fixLargeBanner() {
		$h = $('.stormynd').outerHeight();
		$w = $('.stormynd').outerWidth();
		$('.frontpage-orbit li').height($h);
		$('.frontpage-orbit li').width($w);
	}

	function doStuff() {
		if(Foundation.utils.is_large_up()) {
			$h = $('.stormynd').outerHeight();

			var first = $h/3;
			var second = first*2;
			sizes = [second, first];

			$.each($('.helmingur'), function(i, v) {
				$(v).height(sizes[i]);

				$(v).find('ul li').height(sizes[i]);
			});
		} else if(Foundation.utils.is_medium_up()) {
			$h = $('.map').outerHeight();

			$.each($('.helmingur'), function(i, v) {
				$(v).find('ul li').height($h);
			});
		} else if(Foundation.utils.is_small_up()) {
			$.each($('.helmingur'), function(i, v) {
				$(v).find('ul li').css('min-height', '300px');
			});
		} else { 
			console.log('less than large');
		}
	}


	fixLargeBanner();
	doStuff();
	

});